import { getYxDetail } from "./api";
import { getList } from "./../thirdQradeReceiving/api.js";
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/threeQualityManagement/thirdQradeReport",
          name: "thirdQradeReport",
          title: "终包采购提报"
        },
        {
          path: "/threeQualityManagement/thirdQradeReportDetail",
          name: "activityView",
          title: "终包采购提报详情"
        }
      ],
      pageLoadFlag: false,
      detilInfo: {},
      // 查看详情的表头
      columns: [
        {

          title: "物料编码",
          dataIndex: "code"

        },
        {
          title: "物料名称",
          dataIndex: "name"
        },
        {
          title: "最终数量",
          dataIndex: "finallyNum",
          scopedSlots: { customRender: "finallyNum" }
        },

        {
          title: "备注",
          dataIndex: "remarks",
          scopedSlots: { customRender: "remark" }
        }


      ],
      // 未处理看到的表头
      columns1: [
        {

          title: "物料编码",
          dataIndex: "code"

        },
        {
          title: "物料名称",
          dataIndex: "name"

        },
        {
          title: "最终数量",
          dataIndex: "finallyNum",
          scopedSlots: { customRender: "finallyNum" }
        },
        {
          title: "长",
          dataIndex: "long",
          scopedSlots: { customRender: "long" }
        },
        {
          title: "宽",
          dataIndex: "width",
          scopedSlots: { customRender: "width" }
        },
        {
          title: "米",
          dataIndex: "mete",
          scopedSlots: { customRender: "mete" }
        },
        {
          title: "备注",
          dataIndex: "remark",
          scopedSlots: { customRender: "remark" }
        }


      ],
      tableData: []

    };
  },


  mounted() {
    // 提交的时候传taskId
    if (this.$route.query.taskId) {
      this.getDetailInfo(this.$route.query.taskId);
    } else {
      this.$message.warning("获取详情失败");
    }
  },

  methods: {
    // 获取详情
    getDetailInfo(aid) {
      let data = { taskId: aid };
      getList(data).then(res => {
        this.detilInfo = res.data.data[0];
        this.tableData = res.data.data[0].detailList;
        this.tableData.forEach(item => {
          item.remarks = item.remark;
          if (item.submitQuantity !== 0) {
            item.finallyNum = item.submitQuantity;

          } else {
            item.finallyNum = 0;

          }
        });
        // 根据状态判断表格表头显示
        if (this.detilInfo.status == "0") {
          this.columns = this.columns1;
        }
      });
    },
    // 返回
    goback() {
      window.close();
      this.$router.push({ path: "/threeQualityManagement/thirdQradeReport" });
    },
    // 提交
    sumbit(status) {
      // status 1：提交，0：保存
      let detailList = [];
      this.tableData.forEach((item) => {
        let remark = item.remarks || "";
        // 根据返回数据米或者平方米来判断传给后台数据
        if (item.unit == "米(M)") {
          remark = `${remark} {米数${item.mete}*数量${item.finallyNum}}`;
        }
        if (item.unit == "平方米(M2)") {
          remark = `${remark} {长${item.long}*宽${item.width}*数量${item.finallyNum}}`;
        }
        detailList.push({
          id: item.id,
          quantity: item.finallyNum,
          remark: remark
        });
      });
      let data = {
        id: this.$route.query.taskId,
        status: status,
        detailList: detailList
      };
      this.pageLoadFlag = true;
      getYxDetail(data).then(res => {
        if (res.data.msg == "操作成功") {
          this.$message.success(res.data.msg);
          this.pageLoadFlag = false;
          window.opener.location.reload();
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback();
          }, 2000);
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    }
  }
};