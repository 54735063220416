import http from "@/utils/request";
//   获取营销模式的列表
export function getYxDetail(data) {
    return http({
        method: "post",
        url: "/pms/pmsApi/pmsShopPlanShopSubmit.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}